import { setAuthentication } from '~/assets/js/helpers/commerce'
import { useAlertStore } from '~/stores/alerts'
import { useCouponStore } from '~/stores/coupon'

export default ({ state, order, cart, $track, $sentry }) => ({
  async add(code, silent = false) {
    const alertStore = useAlertStore()

    if (!state.cart.id) await cart.create()
    
    try {
      await setAuthentication(state)

      const res = await order.update(state.cart.id)({
        gift_card_or_coupon_code: code.padEnd(8, '-'),
      })
      
      if (res)
        state.cart = res

      if (!silent)
        alertStore.ADD_MESSAGE({ body: `Coupon code ${code} applied.` })

      // If this was a coupon code rather than a gift card then get promo actions
      if (state.cart.couponCode) await this._handlePromoActions(code)

      await $track.coupon.applied(res, code)

      return {
        status: 'SUCCESS',
        message: `Coupon code ${code} was applied.`,
        cart: res,
        mode: state.cart.couponCode ? 'coupon' : 'gift_card'
      }
    } catch (error) {
      if (
        !error?.message?.includes("gift_card_or_coupon_code - doesn't exist")
      ) {
        // Only report error to Sentry if it's not a gift card simply missing
        $sentry?.captureException(error)
      }

      console.error(error)
      state.cart = await order.read(state.cart.id)
      await $track.coupon.denied(state.cart, code)
      
      alertStore.ADD_ERROR_MESSAGE({
        body: `Coupon code ${code} is not valid.`,
      })

      return {
        status: 'INVALID',
        message: `Coupon code ${code} is not valid.`,
      }
    }
  },

  async remove(silent = false) {
    const alertStore = useAlertStore()

    try {
      await setAuthentication(state)

      const res = await order.update(state.cart.id)({
        gift_card_code: '',
        coupon_code: '',
      })
      state.cart = res

      await $track.coupon.removed(res)

      if (!silent) alertStore.ADD_MESSAGE({ body: 'Coupon code has been removed.' })
      return {
        status: 'SUCCESS',
        message: 'Coupon code has been removed.',
        cart: res,
      }
    } catch (error) {
      $sentry?.captureException(error)
      console.error(error)

      state.cart = await order.read(state.cart.id)

      alertStore.ADD_MESSAGE({ body: 'Coupon code could not be removed.' })
      return {
        status: 'FAILED',
        message: 'Coupon code could not be removed.',
      }
    }
  },

  async _handlePromoActions(code) {
    const couponStore = useCouponStore()

    // Get coupon metadata from the pinia store
    // We can't just use CL response as it doesn't include the code metadata
    await couponStore.GET_COUPON_DETAILS(code)
    const coupon = couponStore.couponDetails[code] || []

    // Check if any products need to be added to the cart
    const productsToAdd = {}
    coupon.forEach((item) => {
      if (item?.type !== 'add_products') return

      item.products.forEach((product) => {
        productsToAdd[product.sku] = product.qty
      })
    })

    Object.entries(productsToAdd).forEach(async ([sku, qty]) => {
      // Check current qty in cart
      const currentQty =
        state.cart.discreteLineItems?.find((item) => item.sku === sku)
          ?.quantity || 0

      // Add the difference to the cart
      if (currentQty < qty) {
        const qtyToAdd = qty - currentQty
        await cart.add([
          {
            sku,
            quantity: qtyToAdd,
            isLimitedEdition: true,
            legacySKU: sku,
          },
        ])
      }
    })
  },
})
