import cookie from 'js-cookie'
import { setAuthentication } from '~/assets/js/helpers/commerce'
import { useAlertStore } from '~/stores/alerts'
import { useUserStore } from '~/stores/user'

const bundleOptionSKUs = (bundleSKU) => {
  return bundleSKU?.split('-') || []
}

export default ({
  state,
  order,
  lineItem,
  lineItemOption,
  $product,
  $sentry,
}) => ({
  adjustBundleLineItems(lineItems) {
    return lineItems.reduce((accumulator, lineItem) => {
      const optionSKUs = lineItem.isLimitedEdition
        ? [lineItem.sku]
        : bundleOptionSKUs(lineItem.sku)
      if (optionSKUs.length > 1) {
        state.cart.lineItems?.forEach((cartLineItem) => {
          if (cartLineItem.metadata?.bundleId === lineItem.sku) {
            const multiplier = cartLineItem.metadata?.multiplier || 1
            accumulator.push({
              id: cartLineItem.id,
              quantity: multiplier * lineItem.quantity,
              metadata: {
                ...cartLineItem.metadata,
                ...lineItem.metadata,
              },
            })
          } else if (cartLineItem.metadata?.bundleCode === lineItem.sku) {
            const multiplier = cartLineItem.metadata?.multiplier || 1
            accumulator.push({
              id: cartLineItem.id,
              quantity: multiplier * lineItem.quantity,
              metadata: {
                ...cartLineItem.metadata,
                ...lineItem.metadata,
              },
            })
          }
        })
      } else {
        state.cart.lineItems?.forEach((cartLineItem) => {
          if (cartLineItem.sku === lineItem.sku) {
            const multiplier = cartLineItem.metadata?.multiplier || 1
            accumulator.push({
              id: cartLineItem.id,
              quantity: multiplier * lineItem.quantity,
              metadata: {
                ...cartLineItem.metadata,
                ...lineItem.metadata,
              },
            })
          }
        })
      }

      return accumulator
    }, [])
  },

  getBundleLineItems(lineItems) {
    return lineItems
      .reduce((accumulator, lineItem) => {
        const optionSKUs = lineItem.isLimitedEdition
          ? [lineItem.sku]
          : bundleOptionSKUs(lineItem.sku)
        if (optionSKUs.length > 1) {
          state.cart.lineItems?.forEach((otherLineItem) => {
            if (otherLineItem.metadata?.bundleId === lineItem.sku)
              accumulator.push(otherLineItem)
            else if (otherLineItem.metadata?.bundleCode === lineItem.sku)
              accumulator.push(otherLineItem)
          })
        } else {
          accumulator.push(
            state.cart.lineItems?.find(
              (otherLineItem) => otherLineItem.sku === lineItem.sku
            )
          )
        }

        return accumulator
      }, [])
      .filter((lineItem) => lineItem)
  },

  getBundleLineItemIds(lineItems) {
    return this.getBundleLineItems(lineItems).map((lineItem) => lineItem.id)
  },

  createBundleLineItems(lineItems) {
    return lineItems.reduce((accumulator, lineItem) => {
      const optionSKUs = lineItem.isLimitedEdition
        ? [lineItem.sku]
        : bundleOptionSKUs(lineItem.sku)
      const isBundle = optionSKUs.length > 1
      const bundleId = `${Math.floor(Math.random() * 1e4)}-${Math.floor(
        Math.random() * 1e4
      )}`

      if (!isBundle) {
        accumulator.push({
          ...lineItem,
          metadata: {
            ...lineItem.metadata,
            invitedToPreOrder: lineItem?.invitedToPreOrder,
            additionalProductSKU: lineItem?.additionalProductSKU,
          },
        })
      } else {
        optionSKUs.forEach((optionSKU) => {
          // If a multiplier is included in the SKU then strip it out
          // eg `gc_increment*50` will become `gc_increment` and multiplier will be set to 50
          let multiplier = 1
          let sku = optionSKU
          if (sku.includes('*')) {
            [sku, multiplier] = optionSKU.split('*')
            multiplier = parseInt(multiplier)
          }

          accumulator.push({
            sku,
            quantity: lineItem.quantity * multiplier,
            image: lineItem.image,
            metadata: {
              ...lineItem.metadata,
              bundleCode: lineItem.sku,
              isLimitedEdition: lineItem.isLimitedEdition,
              legacySKU: lineItem.legacySKU,
              adFeedSKU: lineItem.adFeedSKU,
              multiplier,
              bundleId,
              additionalProductSKU: lineItem?.additionalProductSKU,
              lensUpdated: !!lineItem?.lensUpdated,
              originalLensOptions: lineItem?.lensOptions,
              productOption: lineItem?.productOption,
              invitedToPreOrder: lineItem?.invitedToPreOrder,
            },
          })
        })
      }

      return accumulator
    }, [])
  },

  async create() {
    await setAuthentication(state)

    const userStore = useUserStore()
    const metadata = {
      userUUID: userStore.uuid
    }
    if (userStore.referrer) metadata.referrer = userStore.referrer

    state.cart = await order.create()({
      market: {
        type: 'markets',
        id: state.config.market.id,
      },
      metadata
    })

    cookie.set(state.cartName, state.cart.id, { expires: 28 })

    return state.cart
  },

  async fetch(id, enableAutoRefresh = false) {
    await setAuthentication(state)

    state.cart = await (enableAutoRefresh
      ? order.enableAutoRefresh(id)
      : order.read(id))

    // Check that we don't have any orphaned line items in cart
    let hasUpdated = await this.removeOrphanedLineItems()

    try {
      hasUpdated |= await this.checkFreeGiftLineItems()
    } catch {
      // do nothing
    }

    if (hasUpdated) {
      state.cart = await order.read(id)
    }

    // Fetch product data for all products in cart
    await $product.get(
      state.cart.lineItems?.map(
        (lineItem) => lineItem.baseSku || lineItem.sku
      ) || []
    )

    return state.cart
  },

  async adjust(lineItems, returnPromises = false) {
    await setAuthentication(state)

    // Disable AutoRefresh
    await order.disableAutoRefresh()

    // Adjust quantity parameter for all line items that make up bundle
    const lineItemOptions = lineItems
      .map(({ quantity, associatedLineItemOptions }) =>
        associatedLineItemOptions?.map((option) => ({ ...option, quantity }))
      )
      .flat()
      .filter((option) => option)
    const adjustedBundleLineItems = this.adjustBundleLineItems(lineItems)

    const patchedLineItems = adjustedBundleLineItems.reduce(
      (acc, { id, quantity, metadata }) => {
        return [...acc, lineItem.update(id)({ quantity, metadata })]
      },
      []
    )
    const patchedLineItemOptions = lineItemOptions.length
      ? lineItemOptions.reduce((acc, { id, quantity, options }) => {
          return [...acc, lineItemOption.update(id)({ quantity, options })]
        }, [])
      : []

    if (returnPromises) {
      return [...patchedLineItems, ...patchedLineItemOptions]
    } else {
      await Promise.all([...patchedLineItems, ...patchedLineItemOptions])
    }

    await this.fetch(state.cart.id, true)

    return state.cart
  },

  async remove(lineItems, options = {}) {
    await setAuthentication(state)

    options = {
      disableAutoRefresh: true,
      fetchOnComplete: true,
      ...options,
    }

    // Disable AutoRefresh
    if (options.disableAutoRefresh) await order.disableAutoRefresh()

    const lineItemIds = this.getBundleLineItemIds(lineItems)

    const removals = lineItemIds.map(lineItem.delete)

    await Promise.all(removals)

    if (options.fetchOnComplete) await this.fetch(state.cart.id, true)

    return state.cart
  },

  async add(lineItems, options = {}) {
    if(!state.cart.id) {
      await this.create()
    }

    const alertStore = useAlertStore()

    await setAuthentication(state)

    options = {
      showErrorMessage: true,
      fetchOnComplete: true,
      disableAutoRefresh: true,
      ...options,
    }

    const testLineItemMatches = (lineItem, otherLineItem) => {
      if (
        otherLineItem.bundleCode === lineItem.sku &&
        otherLineItem.isBaseProduct
      )
        return true
      if (
        lineItem.isLimitedEdition &&
        otherLineItem.isLimitedEdition &&
        lineItem.sku === otherLineItem.sku
      )
        return true
      return false
    }

    const testForceNewLineItemOnAdd = (lineItem) => {
      if (lineItem.sku.startsWith('case')) return true
      if (lineItem.sku.includes('le_RX')) return true
      if (lineItem.metadata?.isFreePromoProduct) return true
      return false
    }

    // Disable AutoRefresh
    if (options.disableAutoRefresh) await order.disableAutoRefresh()

    // Identify any lineItems which have a bundleCode matching a lineItem already in cart
    const existingBundleLineItems = []
    const newOrSimpleLineItems = []
    lineItems.forEach((lineItem) => {
      const forceNewLineItem = testForceNewLineItemOnAdd(lineItem)
      if (
        !forceNewLineItem &&
        state.cart.lineItems?.some(
          (item) => testLineItemMatches(lineItem, item)
        )
      ) {
        existingBundleLineItems.push(lineItem)
      } else {
        newOrSimpleLineItems.push(lineItem)
      }
    })

    // Update quantity of existing line items
    const lineItemsToUpdate = existingBundleLineItems.map((lineItem) => {
      const existingLineItem = state.cart.lineItems?.find((item) =>
        testLineItemMatches(lineItem, item)
      ) || {}
      return {
        ...lineItem,
        quantity: existingLineItem.quantity + lineItem.quantity,
      }
    })
    const patchedLineItems = lineItemsToUpdate.length
      ? await this.adjust(lineItemsToUpdate, true) // Returns an array of promises
      : []

    // Add new line items
    const newBundleLineItems = await this.createBundleLineItems(
      newOrSimpleLineItems
    )
    const postLineItems = newBundleLineItems
      .map(async ({ quantity, sku, image = '', metadata }) => {
        let _metadata = metadata || {}
        const requests = []
        const hasProductOption = _metadata && !!_metadata?.productOption

        if (hasProductOption) {
          const { productOption, ...rest } = _metadata

          _metadata = {
            ...rest,
            prescription: productOption?.tags?.some((tag) =>
              tag.value.includes('prescription') || tag.value.includes('eyeglasses')
            ),
          }
        }

        const lineItemResponse = await lineItem.create(state.cart.id)({
          quantity,
          sku_code: sku,
          _update_quantity: false,
          image_url: image,
          metadata: _metadata,
        })

        if (hasProductOption) {
          const { productOption } = metadata
          const lineItemSkuPrefix = sku.split('_')[0]
          const productOptionSkuPrefix = productOption.sku.split('_')[0]

          if (lineItemSkuPrefix === productOptionSkuPrefix) {
            const { scmId, _name, id, lensTech } = productOption

            const lineOptionResponse = await lineItemOption.create(
              lineItemResponse.get('id')
            )({
              scmId,
              _name,
              id,
              bundleCode: metadata.bundleCode,
              bundleId: metadata.bundleId,
              quantity,
              lensTech,
            })
            requests.push(lineOptionResponse)
          }
        }

        return [...requests, lineItemResponse]
      })
      .flat()

    const responses = await Promise.allSettled([
      ...postLineItems,
      ...patchedLineItems,
    ])

    const anyFailures = responses.some(
      (response) => response.status === 'rejected'
    )

    // Fetch cart even if we've had a failure so we know what state we're in
    // We call enableAutoRefresh here as we previously disabled it
    if (options.fetchOnComplete) await this.fetch(state.cart.id, true)

    if (anyFailures) {
      $sentry.captureException(new Error('Failed to add item to basket'))
      if (options.showErrorMessage) {
        alertStore.ADD_ERROR_MESSAGE({
          body: 'Unable to add to basket. Please try again.'
        })
      }
      throw new Error('Failed to add to cart')
    }

    return state.cart
  },

  async validateAndCorrectOrder() {
    /**
     * This function can be called at any time and will check the order
     * object for common issues and attempt to correct them.
     *
     * - Address name contains 'EXPRESS CHECKOUT' - remove address
     * - Cart contains orphaned line items (no matching base product) - remove them
     */

    // Address name contains 'EXPRESS CHECKOUT' - remove address
    const hasUpdated = await this.removeInvalidAddresses(true)

    if (hasUpdated) {
      await this.fetch(state.cart.id, true)
    }

    // Cart contains orphaned line items (no matching base product) - remove them
    await this.removeOrphanedLineItems()

    try {
      await this.checkFreeGiftLineItems()
    } catch {
      // do nothing
    }
  },

  async removeInvalidAddresses(deferFetch = false) {
    let hasUpdated = false

    if (
      state.cart.shippingAddress?.firstName === 'EXPRESS' &&
      state.cart.shippingAddress?.lastName === 'CHECKOUT'
    ) {
      await order.removeAddress(state.cart.shippingAddress.id)
      hasUpdated = true
    }

    if (
      state.cart.billingAddress?.firstName === 'EXPRESS' &&
      state.cart.billingAddress?.lastName === 'CHECKOUT'
    ) {
      await order.removeAddress(state.cart.billingAddress.id)
      hasUpdated = true
    }

    if (hasUpdated && !deferFetch) {
      await this.fetch(state.cart.id, true)
    }

    return hasUpdated
  },

  async removeOrphanedLineItems() {
    const baseProductMissing = (lineItem) =>
      lineItem.isBundle &&
      state.cart.lineItems?.every(
        (otherLineItem) =>
          !(
            otherLineItem.bundleCode === lineItem.bundleCode &&
            otherLineItem.isBaseProduct
          )
      )

    const childProductsMissing = (lineItem) => {
      if (!lineItem.isBundle || !lineItem.isBaseProduct) return false

      const childSKUs = lineItem.bundleSkus
        .filter((sku) => sku !== lineItem.sku)
        .map((sku) => sku.split('*')[0])
      return childSKUs.some((sku) => {
        const testBundleCodeMatches = (otherLineItem) =>
          otherLineItem.bundleCode === lineItem.bundleCode
        const testSKUMatches = (otherLineItem) => otherLineItem.sku === sku
        return !state.cart.lineItems
          .filter(testBundleCodeMatches)
          .some(testSKUMatches)
      })
    }

    const orphanedLineItems = state.cart.lineItems?.filter(
      (lineItem) =>
        baseProductMissing(lineItem) || childProductsMissing(lineItem)
    ) || []
    if (orphanedLineItems.length) await this.remove(orphanedLineItems)

    return orphanedLineItems.length > 0
  },

  async checkFreeGiftLineItems() {
    // Check that there are no black friday items without a parent
    const itemIds = state.cart.lineItems.map(li => li.id)
    const itemsToRemove = state.cart.lineItems.filter(
      li => li.metadata?.isFreePromoProduct
        && !itemIds.includes(li.metadata?.parentId)
    )
    if (itemsToRemove.length) {
      await Promise.all(itemsToRemove.map(
        li => lineItem.delete(li.id)
      ))
    }

    // Check black friday items have a quantity of 1
    const bfLineItems = state.cart.lineItems.filter(
      li => li.metadata?.isFreePromoProduct
    )
    const itemsToUpdate = bfLineItems.filter(li => li.quantity > 1)
    if (itemsToUpdate.length) {
      await Promise.all(itemsToUpdate.map(
        li => lineItem.update(li.id)({ quantity: 1 })
      ))
    }

    return itemsToRemove.length || itemsToUpdate.length
  }
})
