export const getSkuWithoutLensTech = (sku, partType) => {
  if (!sku) return null

  // If partType is not lenses, return the sku
  if (partType && partType !== 'lenses') return sku

  // If partType is lenses, remove the lens technology from the sku
  // Matches any of the following strings: _P, _N, _8P, _8, or _RX, and replaces them with underscore (_)
  // Matches either the string 'N' or the string '8N' at the end of the SKU, removes it if it exists
  return sku.replace(/_(P|N|8P|8|RX|STRX|STPRX|THRX|THPRX|STCRX|THCRX)/g, '_').replace(/(N|8N)$/g, '')
}
