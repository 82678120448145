import { gql } from "graphql-tag";

export const productVariant = gql`
  fragment productVariant on ProductVariant {
    availableForSale
    sku
    compareAtPriceV2 {
      amount
      currencyCode
    }
    currentlyNotInStock
    id
    priceV2 {
      amount
      currencyCode
    }
    product {
      handle
      id
      featuredImage {
        ... on Image {
          thumbnail: url(transform: { maxWidth: 160, maxHeight: 160 })
        }
        altText
        height
        width
      }
      tags
      title
    }
    selectedOptions {
      name
      value
    }
  }`

export const cartLines = gql`
  fragment cartLines on Cart {
    lines(first: 50) {
      edges {
        cursor
        node {
          attributes {
            key
            value
          }
          discountAllocations {
            discountedAmount {
              amount
              currencyCode
            }
          }
          cost {
            subtotalAmount {
              amount
              currencyCode
            }
            totalAmount {
              amount
              currencyCode
            }
          }
          id
          merchandise {
            ... on ProductVariant {
              ...productVariant
            }
          }
          quantity
          ...on ComponentizableCartLine {
            id
            lineComponents {
              attributes {
                key
                value
              }
              discountAllocations {
                discountedAmount {
                  amount
                  currencyCode
                }
              }
              cost {
                subtotalAmount {
                  amount
                  currencyCode
                }
                totalAmount {
                  amount
                  currencyCode
                }
              }
              merchandise {
                ... on ProductVariant {
                  ...productVariant
                }
              }
              quantity
            }
          }
        }
      }
    }
  }
  ${productVariant}
`;

export type CartLines = {
  lines: {
    edges: {
      cursor: string;
      node: CartLine;
    }[]
  }
}

export type ProductVariant = {
  availableForSale?: boolean;
  sku?: string;
  compareAtPriceV2?: {
    amount: string;
    currencyCode: string;
  };
  currentlyNotInStock?: boolean;
  id?: string;
  priceV2?: {
    amount: string;
    currencyCode: string;
  };
  product?: {
    handle?: string;
    id?: string;
    featuredImage?: {
      thumbnail?: string;
      altText?: string;
      height?: number;
      width?: number;
    };
    tags?: string[];
    title?: string;
  };
  selectedOptions?: {
    name: string;
    value: string;
  }[];
}

export type CartLine = {
  attributes: {
    key: string;
    value: string;
  }[];
  discountAllocations: {
    discountedAmount: {
      amount: string;
      currencyCode: string;
    };
  }[];
  cost: {
    subtotalAmount: {
      amount: string;
      currencyCode: string;
    };
    totalAmount: {
      amount: string;
      currencyCode: string;
    };
  };
  id: string;
  merchandise: ProductVariant;
  quantity: number;
  lineComponents?: {
    attributes: {
      key: string;
      value: string;
    }[];
    discountAllocations: {
      discountedAmount: {
        amount: string;
        currencyCode: string;
      };
    }[];
    cost: {
      subtotalAmount: {
        amount: string;
        currencyCode: string;
      };
      totalAmount: {
        amount: string;
        currencyCode: string;
      };
    };
    merchandise: ProductVariant;
    quantity: number;
  }[];
}