import { computed } from 'vue'

export default () => {
  const checkoutStore = useCheckoutStore()
  const shopifyStore = useShopifyStore()
  const { $commerce, $i18n } = useNuxtApp()
  const commerceSource = useCommerceSource()

  const storeCountry = computed(() => {
    return $i18n.locale.value.split('-')[1].toUpperCase()
  })

  const storeCurrency = computed(() => {
    if (commerceSource === 'shopify') {
      return shopifyStore.currencyCode
    } else if (commerceSource === 'commercelayer') {
      // Attempt to get currency code from order
      const orderCurrencyCode = checkoutStore?.currencyCode
      if (orderCurrencyCode) return orderCurrencyCode

      // Attempt to get currency code from commerce plugin state
      const market = $commerce.state.config.market
      if (market) return market.currency

      // Attempt to get currency code from country
      const country = $i18n.locales.find(
        country =>
          country.countryCode === storeCountry.value.toLowerCase()
      )
      if (country) return country.currency

      throw new Error('Unable to determine currency')
    }

    return 'GBP'
  })

  return { storeCurrency, storeCountry }
}
