function getCommerceSource(
  commerceSource: string,
  locale: string,
  shopifyEnabledLocales: Array<string>
) {
  if (shopifyEnabledLocales.includes(locale) && commerceSource === 'shopify') {
    return 'shopify'
  } else {
    return 'commercelayer'
  }
}

export default function useCommerceSource (commerceSourceParam?: string, localeParam?: string): 'shopify' | 'commercelayer' {
  const { public: { commerceSource, shopifyEnabledLocales } } = useRuntimeConfig()
  const calculatedShopifyEnabledLocales: Array<string> = shopifyEnabledLocales.split(',') || []

  if (commerceSourceParam && localeParam) {
    return getCommerceSource(commerceSourceParam, localeParam, calculatedShopifyEnabledLocales)
  } else {
    const route = useRoute()
    const locale = route.params.locale as string || 'en-gb'

    return getCommerceSource(commerceSource, locale, calculatedShopifyEnabledLocales)

  }
}