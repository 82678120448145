const pattern = new RegExp(/^\/[a-z]{2}-([a-z]{2})\/?/)

export function useUrlBuilder() {
  function slugToPagePath (slug: string) {
    if (!slug) return ''
    return slug.replace(/__/g, '/').replace(/^\/+/, '')
  }

  function resolveLocalePath (to: string): string {
    to = (to || '').replace(/\/+$/, "")
    
    const { $i18n } = useNuxtApp()

    if(to.startsWith('https://') || to.startsWith('http://')) return to

    // If the path is already locale prefixed, return
    if (pattern.test(to)) {
      return to
    }

    // Construct localised path removing any leading or trailing slashes from the 'to' param
    return ('/' + $i18n.locale.value + '/' + to.replace(/^\/+/, '')).replace(/\/+$/, "")
  }

  interface Item {
    linkText: string
    internalLink: {
      slug: string
      _modelApiKey: string | undefined
      category: {
        slug: string | undefined
      }
    }
    internalLinkQueryParams: string | undefined
  }

  function buildInternalLink(item: Item) {
    const linkQueryParams = item?.internalLinkQueryParams ? item.internalLinkQueryParams : ''
    let pathPrefix = ''

    if (!item?.internalLink) return null

    switch (item.internalLink._modelApiKey) {
      case 'composite_pdp':
        pathPrefix = 'shop/'
        break
      case 'article_category':
        pathPrefix = 'explore/'
        break
      case 'article':
        pathPrefix = 'explore/' + item.internalLink.category?.slug + '/'
        break
      default:
        pathPrefix = ''
    }

    return resolveLocalePath(
      pathPrefix + slugToPagePath(item.internalLink.slug)
    ) + linkQueryParams
  }

  return { resolveLocalePath, slugToPagePath, buildInternalLink }
}
