import { Product, Part, PartOption, Swatch } from './product/models'
import CustomisedProduct from '~/models/CustomisedProduct'
import { useCheckoutStore } from '~/stores/checkout'
import { useShopifyStore } from '~/stores/shopify'
import { locales } from '~/i18n/constants.ts'
import { getMarket } from '~/assets/js/helpers/markets'
import { shallowRef } from 'vue'

export default defineNuxtPlugin(async (nuxtApp) => {
  const { $i18n } = useNuxtApp()
  const runtimeConfig = useRuntimeConfig()

  const { localeForDato } = useDatoLocale()
  const commerceSource = useCommerceSource()

  const state = useState('productPlugin', () => shallowRef({
    products: {},
    parts: {},
    options: {},
    swatches: {},
    outOfStockProductSkus: [],
    preOrderProductSkus: [],
    market: getMarket(
      $i18n.locale.value,
      runtimeConfig.public.environmentName,
      locales
    )
  }))

  const getters = {
    product: (sku, currency) => {
      const productData = state.value.products?.[`${currency}:${sku}`]
      return productData ? Product.fromJSON(productData, getters.part) : null
    },
    part: (partId) => {
      const partData = state.value.parts?.[partId]
      return partData ? Part.fromJSON(partData, getters.option) : null
    },
    option: (optionId) => {
      const optionData = state.value.options?.[optionId]
      return optionData ? PartOption.fromJSON(optionData, getters.swatch) : null
    },
    swatch: (swatchId) => {
      const swatchData = state.value.swatches?.[swatchId]
      return swatchData ? Swatch.fromJSON(swatchData) : null
    },
    outOfStockProductSkus: () => state.value.outOfStockProductSkus,
    preOrderProductSkus: () => state.value.preOrderProductSkus,
  }

  const mutations = {
    ADD_PRODUCTS(products) {
      products = Array.isArray(products) ? products : [products]
      products.forEach((product) => {
        if (!product.currency)
          throw new Error('Unable to determine product currency')

        // If product already stored with PDP config then skip
        if (state.value.products[`${product.currency}:${product.sku}`]?.pdpLoaded)
          return
        state.value.products[`${product.currency}:${product.sku}`] = product.toJSON()
      })
    },

    ADD_PARTS(parts) {
      parts = Array.isArray(parts)
        ? Object.fromEntries(parts.map((part) => [part.id, part.toJSON()]))
        : Object.fromEntries(Object.entries(parts).map(
          ([id, part]) => [id, part.toJSON()])
        )

      state.value.parts = {
        ...state.value.parts,
        ...parts,
      }
    },

    ADD_OPTIONS(options) {
      options = Array.isArray(options)
        ? Object.fromEntries(options.map(
          (option) => [option.id, option.toJSON()])
        )
        : Object.fromEntries(Object.entries(options).map((
          [id, option]) => [id, option.toJSON()])
        )

      state.value.options = {
        ...state.value.options,
        ...options,
      }
    },

    ADD_SWATCHES(swatches) {
      swatches = Array.isArray(swatches) ? swatches : [swatches]
      
      swatches
        .filter((swatch) => swatch)
        .forEach((swatch) => {
          state.value.swatches[swatch.id] = swatch.toJSON()
        })
    },

    ADD_OUT_OF_STOCK(sku) {
      state.value.outOfStockProductSkus.push(sku)
    },

    ADD_PRE_ORDER(sku) {
      state.value.preOrderProductSkus.push(sku)
    },
  }

  function createNewProduct(productData, includePDPConfig, currency) {
    const getPart = getters.part
    const getOption = getters.option
    const getSwatch = getters.swatch

    const allParts = productData.parts || []
    const allOptions = allParts.map((part) => part.options).flat()
    const allSwatches = allOptions.map((option) => option.swatchStyle)

    // Add new swatches to the store
    const newSwatches = allSwatches
      .map((swatch) => {
        if (!swatch?.id || getSwatch(swatch.id)) return null
        return new Swatch({ ...swatch, image: swatch.image?.src })
      })
      .filter((swatch) => swatch)
    mutations.ADD_SWATCHES(newSwatches)

    // Add new options to the store
    const newOptions = allOptions
      .map((option) => {
        if (getOption(option.id)) return null
        const optionInstances = []

        const optionInstance = new PartOption(option)
        optionInstance.addSwatch(getSwatch)
        return [...optionInstances, optionInstance]
      })
      .flat()
      .filter((option) => option)
    mutations.ADD_OPTIONS(newOptions)

    // Add new parts to the store
    const newParts = allParts
      .map((part) => {
        const existingPart = getPart(part.id)
        if (existingPart) {
          // Part exists, ensure it's got the correct options
          existingPart.addOptions(getOption)
          return null
        }

        const partInstance = new Part(part)
        partInstance.addOptions(getOption)
        return partInstance
      })
      .filter((part) => part)
    mutations.ADD_PARTS(newParts)

    // Create a new product instance and add it to state
    productData.currency = productData.currency || currency
    const product = new Product(productData, includePDPConfig, nuxtApp.$abt)
    product.addParts(getPart)
    mutations.ADD_PRODUCTS(product)

    // Add base product
    if (productData.baseProduct)
      product.baseProduct = createNewProduct(
        productData.baseProduct,
        includePDPConfig,
        currency
      )

    return product
  }

  function updateOutOfStockProductSkus(sku) {
    if (state.value.outOfStockProductSkus.includes(sku)) return
    mutations.ADD_OUT_OF_STOCK(sku)
  }

  function updatePreOrderProductSkus(sku) {
    if (state.value.preOrderProductSkus.includes(sku)) return
    mutations.ADD_PRE_ORDER(sku)
  }

  const getProduct = getters.product

  async function get(baseSKUs = [], includePDPConfig = false, preview = false) {
    const checkoutStore = useCheckoutStore()
    const shopifyStore = useShopifyStore()
    
    let currency;

    if (commerceSource === 'shopify') {
      currency = shopifyStore.currencyCode
    } else {
      currency = checkoutStore.market.currency
    }

    if (!currency) throw new Error('Unable to determine currency')

    let getProductsFromState

    try {
      getProductsFromState = (baseSKUs) => {
        return baseSKUs.map((SKU) => getProduct(SKU, currency))
      }
    } catch(error) {
      console.log('getProductsFromState', error)
    }

    const isSingleProduct = typeof baseSKUs === 'string'

    if (isSingleProduct) baseSKUs = [baseSKUs]

    // Ensure baseSKUs is unique
    baseSKUs = [...new Set(baseSKUs)]

    const baseSKUsToFetch = baseSKUs.filter((baseSKU) => {
      return (
        !getProduct(baseSKU, currency) || // We haven't fetched this product
        (includePDPConfig && !getProduct(baseSKU, currency).pdpLoaded)
      ) // We haven't got PDP blocks or don't need them
    })

    if (!baseSKUsToFetch.length) {
      const products = getProductsFromState(baseSKUs)
      return isSingleProduct ? products[0] : products
    }

    const params = {}

    if (includePDPConfig) params.pdp = 1

    if (preview) params.preview = 1

    const productData = await getProductData(baseSKUsToFetch, params)

    Object.values(productData)
      .filter((p) => p)
      .forEach((product) => {
        createNewProduct(product, includePDPConfig, currency)
      })

    const products = getProductsFromState(baseSKUs)

    return isSingleProduct ? products[0] : products
  }

  /**
   * This function fetches product data from the API.
   * We debounce this function to avoid making multiple requests for the same products
   * and to batch products into single calls.
   *
   * @param {*} baseSKUsToFetch An array of base product SKUs to fetch
   * @param {*} params Query parameters to pass to the API
   * @returns
   */
  let timer = null
  let queue = []
  async function getProductData(baseSKUsToFetch, params) {
    if (timer) clearTimeout(timer)

    return new Promise((resolve) => {
      queue.push({ baseSKUsToFetch, params, resolve })
      timer = setTimeout(processGetProductDataQueue, 10)
    })
  }

  async function processGetProductDataQueue() {
    // Group queue by params as these must be the same for each request
    const queueGroupedByParams = queue.reduce((acc, request) => {
      const jointParamsForKey = {
        ...request.params
      }

      const key = JSON.stringify(jointParamsForKey)

      if (!acc[key]) acc[key] = []

      acc[key].push(request)
      return acc
    }, {})
    const workingQueue = queue
    queue = []

    const batchRequests = Object.entries(queueGroupedByParams).map(
      async ([key, queueGroup]) => {
        const params = queueGroup[0].params
        const baseSKUsToFetch = [
          ...new Set(
            queueGroup.map((request) => request.baseSKUsToFetch).flat()
          ),
        ]
        return [
          key,
          await $fetch(
            [
              runtimeConfig.public.productApiPath,
              state.value.market.id,
              baseSKUsToFetch.sort(),
            ].join('/'),
            {
              params: {
                ...params,
                /**
                 *
                 * Only add the locale if the source is calculated as being shopify
                 * in the first place to avoid needless cache busting on other locales
                 *
                 **/
                ...(commerceSource === 'shopify' ? { locale: $i18n.locale.value } : {}),
                datoLocale: localeForDato,
              },
              headers: {
                Accept: 'application/json',
              },
            }
          ),
        ]
      }
    )

    const batchedResponses = await Promise.all(batchRequests)

    // Resolve each request with the correct response
    workingQueue.forEach((request) => {
      const response = batchedResponses.find(([key]) => {
        return key === JSON.stringify(request.params)
      })[1]
      const formattedResponse = Object.fromEntries(
        request.baseSKUsToFetch.map((baseSKU) => [baseSKU, response[baseSKU]])
      )
      request.resolve(formattedResponse)
    })
  }

  async function getFromLandingPageResponse(landingPage) {
    /**
     * Determine all products that are shown in a landing page and fetch corresponding products
     */

    // Recursively extract base product SKUs
    const extractProducts = (any) => {
      if (Array.isArray(any))
        return any
          .map(extractProducts)
          .flat()
          .filter((product) => product)
      if (typeof any === 'object' && any !== null) {
        if (any?._modelApiKey === 'product' || any?.type === 'product')
          return any.baseProduct.sku
        return Object.values(any)
          .map(extractProducts)
          .flat()
          .filter((product) => product)
      }
      return null
    }

    const SKUs = [...new Set(extractProducts(landingPage))]
    return await get(SKUs)
  }

  async function getCustomisedProduct(
    baseSKU,
    initState = {},
    includePDPConfig = false,
    callback
  ) {
    const product = await get(baseSKU, includePDPConfig)

    const customisedProduct = new CustomisedProduct(product, initState)
    if (callback) return callback(customisedProduct)
    return customisedProduct
  }

  function getCustomisedProductSync(baseSKU, initState = {}) {
    const checkoutStore = useCheckoutStore()
    const shopifyStore = useShopifyStore()
    let currency

    if(commerceSource === 'shopify') {
      currency = shopifyStore.currencyCode
    } else {
      currency = checkoutStore.market.currency
    }

    const product = getProduct(baseSKU, currency)

    return new CustomisedProduct(product, initState)
  }

  return {
    name: 'product',
    provide: {
      product: {
        get,
        getFromLandingPageResponse,
        getCustomisedProduct,
        getCustomisedProductSync,
        updateOutOfStockProductSkus,
        updatePreOrderProductSkus,
        state,
      },
    },
  }
})
